import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {Toolbar,Button as MuiButton,} from "@mui/material";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import TelegramIcon from "@mui/icons-material/Telegram";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import PrintIcon from "@mui/icons-material/Print";
import InventoryIcon from "@mui/icons-material/Inventory";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {deleteEmployee} from "../../redux/actions/employeeActions";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import Button from "../../core/controls/Button";
import {
  escalateSalesOrderInternal,
  listSalesOrderInternal,
  listSalesOrderInternalDetails,
} from "../../redux/actions/invoiceActions";
import { Form } from "react-bootstrap";
import {
  SALESORDERINTERNAL_CREATE_RESET,
  SALESORDERINTERNAL_ESCALATION_RESET,
  SALESORDERINTERNAL_UPDATE_RESET,
} from "../../redux/constants/invoiceConstants";
import SalesOrderView from "../../core/SalesOrderView";
import useStyles from "./lcStyle";

const PiLists = ({ history }) => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const salesOrderInternalList = useSelector(
    (state) => state.salesOrderInternalList
  );
  const { loading, error, salesorderinternal } = salesOrderInternalList;

  const salesOrderInternalDetails = useSelector(
    (state) => state.salesOrderInternalDetails
  );
  const {
    loading: loadingDetails,
    error: errorDetails,
    salesorderinternalsingle,
  } = salesOrderInternalDetails;

  const salesOrderInternalUpdate = useSelector(
    (state) => state.salesOrderInternalUpdate
  );
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedSalesOrder,
  } = salesOrderInternalUpdate;

  const salesOrderInternalCreate = useSelector(
    (state) => state.salesOrderInternalCreate
  );
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    createdSalesOrder,
  } = salesOrderInternalCreate;

  const salesOrderInternalEscalate = useSelector(
    (state) => state.salesOrderInternalEscalate
  );
  const {
    loading: loadingEscalate,
    error: errorEscalate,
    success: successEscalate,
    escalatedSalesOrder,
  } = salesOrderInternalEscalate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [pageSize, setPageSize] = React.useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("0");
  const [dataForEscalation, setDataForEscalation] = useState([]);
  const selectedOrderRef = useRef(null);

  const generateRandom = () => {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(listSalesOrderInternal(selectedOrder));
      if (successUpdate) {
        setNotify({
          isOpen: true,
          message: "Updated Successfully",
          type: "success",
        });
        dispatch({ type: SALESORDERINTERNAL_UPDATE_RESET });
      }
      if (successCreate) {
        setNotify({
          isOpen: true,
          message: "Sales Order Created.",
          type: "success",
        });

        dispatch({ type: SALESORDERINTERNAL_CREATE_RESET });
      }

      if (successEscalate) {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setNotify({
          isOpen: true,
          message: "Escalated Successfully",
          type: "error",
        });
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setDataForEscalation([]);

        dispatch({ type: SALESORDERINTERNAL_ESCALATION_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/salesorderinternal" } },
      };
      history.push(location);
    }
  }, [
    dispatch,
    classes,
    history,
    userInfo,
    successUpdate,
    selectedOrder,
    successEscalate,
  ]);

  // const handleEvent = (params) => {
  //   // history.push(`/employee/${params.row.employeeId}/edit`);
  //   // console.log(params.row);
  // };

  const buttonsBasedOnSalesOrderType = () => {
    if (selectedOrder === "0") {
      return (
        <Box className={classes.actions}>
          <Button
            startIcon={<AddIcon fontSize="small" />}
            text="Add New"
            color="primary"
            onClick={() => {
              history.push("/salesorderinternal/create");
            }}
          />
          <Button
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/edit`);
              }
            }}
          />
          <Button
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              if (dataForEscalation.length > 0) {
                setConfirmDialog({
                  isOpen: true,
                  title: "Are you sure that you want to escalate this data?",
                  subTitle: "This data will be sent to the operation team!",
                  onConfirm: () => {
                    dispatch(escalateSalesOrderInternal(dataForEscalation));
                  },
                });
              } else {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "No Data is slected!",
                  isOpen: true,
                });
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "1") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<TelegramIcon fontSize="small" />}
            text="ACTION"
            color="success"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/action`);
              }
            }}
          />
        </Box>
      );
    } else if (selectedOrder === "2") {
      return (
        <Box className={classes.actions2}>
          <Button
            startIcon={<InventoryIcon fontSize="small" />}
            text="PACKAGING"
            color="success"
            onClick={() => {
              if (flag === true || rowId === null) {
                setOpenPopup({
                  ...openPopup,
                  title: "SELECTION ISSUE!!!",
                  subTitle: "Minimum and Maximum one row have to be slected!",
                  isOpen: true,
                });
              }
              if (rowClicked === true) {
                history.push(`/salesorderinternal/${rowId}/package`);
              }
            }}
          />
        </Box>
      );
    }
  };

  const gridBasedOnSalesOrderType = () => {
    // if (selectedOrder === "0") {
    return (
      <div className={classes.colHeader}>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={salesorderinternal}
          columns={
            selectedOrder === "0"
              ? [...columns_part_b_O_0, ...columns_part_a]
              : selectedOrder === "1"
              ? [...columns_part_a, ...columns_part_b_O_1]
              : selectedOrder === "2"
              ? [...columns_part_a, ...columns_part_b_O_2]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.soSystemId + "-" + generateRandom()}
          //onRowClick={handleEvent}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
  };

  const onRowsSelectionHandler = (ids) => {
    if (ids.length > 1) {
      if (selectedOrder === "0") {
        var newids = ids.map((num) => {
          let val = num.split("-");
          var temp = val[0];

          return temp;
        });
        const arrOfNum = newids.map((str) => {
          return Number(str);
        });
        ids = arrOfNum;
        setDataForEscalation(ids);
      }
      setRowClicked(false);
      setFlag(true);
    } else if (ids.length < 1) {
      setRowClicked(false);
      setFlag(true);
      setDataForEscalation([]);
    } else {
      ids = ids[0].split("-");
      var temp = ids[0];
      ids = [];
      ids.push(temp);
      const arrOfNum = ids.map((str) => {
        return Number(str);
      });
      ids = arrOfNum;
      const selectedRowsData = ids.map((id) =>
        salesorderinternal.find((row) => row.soSystemId === id)
      );
      setDataForEscalation([selectedRowsData[0].soSystemId]);
      setRowId(selectedRowsData[0].soSystemId);
      setRowClicked(!rowClicked);
      setFlag(false);
    }
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(deleteEmployee(id));
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
    setRowClicked(false);
    setFlag(false);
  };

  const openModal = (id) => {
    dispatch(listSalesOrderInternalDetails(id)).then((res) => {
      setIsOpen(true);
    });
    //
  };
  const closeModal = (event) => setIsOpen(false);
  const columns_part_a = [
    {
      field: "soSystemNo",
      headerName: "Order No",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "invoiceDate",
      headerName: "Order Date",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "customerName",
      headerName: "Partner Name",
      cellClassName: "name-column--cell",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerName || params.row.supplierName,
    },
    {
      field: "customerAddress",
      headerName: "Partner Address",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.customerAddress || params.row.supplierAddress,

    },
    
    {
      field: "jobType",
      headerName: "Order Type",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "soOrderFrom",
      headerName: "Order From",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "sobFinalBill",
      headerName: "Total Bill",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "soSystemId",
      headerName: "ID",
      headerAlign: "center",
      align: "center",
    },
  ];

  const columns_part_b_O_0 = [
    {
      field: "actions",
      headerName: "EDIT",
      type: "actions",
      renderCell: (params) => {
        // console.log(params);
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<EditOutlinedIcon fontSize="small" />}
            text="Edit"
            color="secondary"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/edit`);
            }}
          />
        );
      },
    },

    {
      field: "actions2",
      headerName: "ESCALATE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            focuselementref={selectedOrderRef}
            startIcon={<SendIcon fontSize="small" />}
            text="ESCALATE"
            color="success"
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure that you want to escalate this data?",
                subTitle: "This data will be sent to the operation team!",
                onConfirm: () => {
                  dispatch(escalateSalesOrderInternal([row.soSystemId]));
                },
              });
            }}
          />
        );
      },
    },
    {
      field: "actions3",
      headerName: "VIEW",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="VIEW"
            focuselementref={selectedOrderRef}
            startIcon={<VisibilityIcon fontSize="small" />}
            text="VIEW"
            color="secondary"
            onClick={() => {
              openModal(row.soSystemId);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_1 = [
    {
      field: "actions",
      headerName: "PUSH FOR PACKAGING",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="PUSH FOR PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<TelegramIcon fontSize="small" />}
            text="PUSH FOR PACKAGING"
            color="success"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/action`);
            }}
          />
        );
      },
    },
  ];

  const columns_part_b_O_2 = [
    {
      field: "actions",
      headerName: "GENERATE INVOICE",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;

        return (
          <MuiButton
            title="PACKAGING"
            focuselementref={selectedOrderRef}
            startIcon={<PrintIcon fontSize="small" />}
            text="PACKAGING"
            color="secondary"
            onClick={() => {
              history.push(`/salesorderinternal/${row.soSystemId}/package`);
            }}
          />
        );
      },
    },
  ];
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Sales Order"
        subtitle="See the total Sales Order List"
      />
      {/* {loadingDelete && <>loading..</>} */}
      {error ? (
        <div className={classes.wrapperWhite}>
          <Toolbar
            className={classes.toolBar}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="m-4"
              src={process.env.PUBLIC_URL + "/images/networkerror.png"}
              alt=""
              width="130"
              height="35"
              style={{ width: "500px", height: "auto" }}
            />
          </Toolbar>
        </div>
      ) : loading ? (
        <div
          className="text-center"
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
        </div>
      ) : (
        <div className={classes.wrapperWhite}>
          <Toolbar className={classes.toolBar}>
            <div style={{ visibility: "hidden" }}>
              <Form.Select
                aria-label="Default select example"
                value={selectedOrder}
                onChange={(e) => {
                  setSelectedOrder(e.target.value);
                }}
              >
                <option value="0">Created Sales Orders</option>
                <option value="1">Escalated Sales Orders</option>
                <option value="2">Sales Orders sent for packaging</option>
              </Form.Select>
            </div>
            {buttonsBasedOnSalesOrderType()}
          </Toolbar>
          {gridBasedOnSalesOrderType()}
          {JSON.stringify(salesorderinternalsingle) !== "{}" &&
            salesorderinternalsingle !== undefined && (
              <SalesOrderView
                showModal={isOpen}
                closeModal={closeModal}
                info={salesorderinternalsingle}
                items={salesorderinternalsingle?.stockOutItemsData}
                currency={"TK"}
                subTotal={
                  salesorderinternalsingle?.stockOutBillData?.calculatedBill
                }
                taxAmmount={
                  salesorderinternalsingle?.stockOutBillData?.billVatPercantage
                }
                discountAmmount={
                  salesorderinternalsingle?.stockOutBillData?.billDiscountAmount
                }
                total={salesorderinternalsingle?.stockOutBillData?.finalBill}
              />
            )}
          <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
          <Notification notify={notify} setNotify={setNotify} />
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </div>
      )}
    </div>
  );
};

export default PiLists;
