import AddCustomer from "./pages/Customers/AddCustomer";
import Customers from "./pages/Customers/Customers";
import EditCustomer from "./pages/Customers/EditCustomer";
import GeoLocation from "./pages/Customers/GeoLocation";
//import Dashboard from "./pages/Dashboard";
import Dashboard from "./pages/Dashboard/Dashboard";
import AddIhouseEmployeedupli from "./pages/Employees/AddIhouseEmployeedupli";
import EditEmployee from "./pages/Employees/EditEmployee";
import InhouseEmployees from "./pages/Employees/InhouseEmployees";
import InhouseEmployeesdupli from "./pages/Employees/InhouseEmployeesdupli";
import InhouseEmployeetry from "./pages/Employees/InhouseEmployeetry";
import CreateIdea from "./pages/Invoices/CreateIdea";
import CreateInvoice from "./pages/Invoices/CreateInvoice";
import ManageRoleAndMenu from "./pages/ManageRoleAndMenu";
import AddMarketingActivity from "./pages/MarketingActivity/AddMarketingActivity";
import EditMarketingActivity from "./pages/MarketingActivity/EditMarketingActivity";
import MarketingActivities from "./pages/MarketingActivity/MarketingActivities";
import AddPrice from "./pages/Price/AddPrice";
import CreateSalesOrderInternal from "./pages/Invoices/CreateSalesOrderInternal";
import Price from "./pages/Price/Price";
import AddProduct from "./pages/Products/AddProduct";
import EditProduct from "./pages/Products/EditProduct";
import MapProduct from "./pages/Products/MapProduct";
import Products from "./pages/Products/Products";
import ChangePassword from "./user/ChangePassword";

import Signup from "./user/Signup";
import MarketingActivitiesDupli from "./pages/MarketingActivity/MarketingActivitiesDupli";
import CustomerMap from "./pages/Customers/CustomerMap";
import MarketingReport from "./pages/Reports/MarketingReports";
import MarketingReportMap from "./pages/MarketingActivity/MarketingReportMap";
import AddTallySales from "./pages/TallySales/AddTallySales";
import UpdateProductSample from "./pages/Products/UpdateProductSample";
import EditSalesOrderInternal from "./pages/Invoices/EditSalesOrderInternal";
import EscalateSalesOrderInternal from "./pages/Invoices/EscalateSalesOrderInternal";
import AddSupplier from "./pages/Suppliers/AddSupplier";
import PackageSalesOrderInternal from "./pages/Invoices/PackagingSalesOrderInternal";
import EscalatedSalesOrderInternals from "./pages/Invoices/EscalatedSalesOrderInternals";
import PackagedSalesOrderInternal from "./pages/Invoices/PackagedSalesOrderInternal";
import EditDemo from "./pages/Invoices/EditDemo";
import PackageDoneSalesOrderInternal from "./pages/Invoices/PackageDoneSalesOrderInternal";
import EditPackagedSalesOrderInternal from "./pages/Invoices/EditPackagedSalesOrderInternal";
import PackagingSalesOrderInternal from "./pages/Invoices/PackagingSalesOrderInternal";
import Suppliers from "./pages/Suppliers/Suppliers";
import EditSupplier from "./pages/Suppliers/EditSupplier";
import UserRoles from "./pages/Registrations/UserRoles";
import SalesOrderInternal from "./pages/Invoices/SalesOrderInternal";
import AssignCustomersToEmployee from "./pages/AssignCustomersToEmployee";
import CreateStockIn from "./pages/stockin/CreateStockIn";
import PurchasePrice from "./pages/PurchasePrice/PurchasePrice";
import AddPurchasePrice from "./pages/PurchasePrice/AddPurchasePrice";
import EditPurchasePrice from "./pages/PurchasePrice/EditPurchasePrice";
import EditPrice from "./pages/Price/EditPrice";
import Batches from "./pages/Batches/Batches";
import Stockin from "./pages/stockin/Stockin";
import EditStockin from "./pages/stockin/EditStockin";
import UpdateStockinQuantity from "./pages/stockin/UpdateStockinQuantity";
import StockinForConfirmation from "./pages/stockin/StockinForConfirmation";
import CreateQuotation from "./pages/Quotations/CreateQuotation";
import EditQuotation from "./pages/Quotations/EditQuotation";
import ApproveQuotation from "./pages/Quotations/ApproveQuotation";
import AddCheque from "./pages/Cheque/AddCheque";
import EditCheque from "./pages/Cheque/EditCheque";
import Cheques from "./pages/Cheque/Cheques";
import QuotationApprovedList from "./pages/Quotations/QuotationApprovedList";
import AddPayment from "./pages/Payment/AddPayment";
import Payments from "./pages/Payment/Payments";
import EditPayment from "./pages/Payment/EditPayment";
import QuotationUnapprovedList from "./pages/Quotations/QuotationUnapproved";
import Quotations from "./pages/Quotations/Quotations";
import AddTask from "./pages/Tasks/AddTask";
import EditTask from "./pages/Tasks/EditTask";
import CreateStockInForm from "./pages/stockin/CreateStockIn";
import AddDelivery from "./pages/Tasks/AddDelivery";
import IncompleteTask from "./pages/Tasks/IncompleteTask";

import StockoutStatus from "./pages/Invoices/StockoutStatus";
import UnAssignedTask from "./pages/Tasks/UnAssignedTask";
import UpdateStockoutStatus from "./pages/Invoices/UpdateStockoutStatus";

import SalesReport from "./pages/Reports/SalesReport";
import DeliveryReport from "./pages/Reports/DeliveryReport";
import ProductStockOutReport from "./pages/Reports/SalesReportByProduct";
import ProductStockInReport from "./pages/Reports/ProductStockIn";
import PaymentReport from "./pages/Reports/PaymentReport";
import MappingReport from "./pages/Reports/MappingReport";
import DeliveryConveyanceReport from "./pages/Reports/DeliveryConveyanceReport";

//Admin Edit

//import SalesOrderInternalGeneralEdit from "./pages/AdminEdit/SalesOrderInternalGeneralEdit";
import SalesOrderInternalGeneralEdit from "./pages/AdminEdit/SalesOrderInternalGeneralEdit";
import EscalateSalesOrderInternalWithPriceEdit from "./pages/AdminEdit/SalesInvoiceAdminEdit/EscalateSalesOrderInternalWithPriceEdit";
import EditSalesOrderInternalAdmin from "./pages/AdminEdit/SalesOrderAdminEdit/EditSalesOrderInternalAdmin";
import EditSalesOrderPacking from "./pages/AdminEdit/SalesPackingEdit/EditSalesOrderPacking";
import SalesPendingPrice from "./pages/AdminEdit/SalesPendingPriceAdminGrid/SalesPendingPrice";

// stock Adjustment

import CreatestockAdjustment from "./pages/StockAdjustment/CreatestockAdjustment";
import StockAdjustmentReport from "./pages/Reports/StockAdjustmentReport";

import CustomerList from "./pages/Customers/CustomerList";

//shahed
import MiniInvoiceLabelSticker from "./pages/Invoices/MiniInvoiceLabelSticker";
import SalesOrderLimitedEdit from "./pages/AdminEdit/SalesOrderLimitedEditGrid";
import LimitedItemsEidtAtInvoice from "./pages/AdminEdit/SalesInvoiceAdminEdit/LimitedItemsEidtAtInvoice";
import LocationList from "./pages/Locations/LocationList";
import AddLocation from "./pages/Locations/AddLocation";
import EditLocation from "./pages/Locations/EditLocation";

import ListProductFunction from "./pages/Products/ProductFunction/ListProductFunction";
import CreateProductFunction from "./pages/Products/ProductFunction/CreateProductFunction";
import UpdateProductFunction from "./pages/Products/ProductFunction/UpdateProductFunction";

import SalesOrderPackageStickerList from "./pages/Sticker/RepackingSticker/SalesOrderPackageStickerList";
import StockOutDefinePackingForSticker from "./pages/Sticker/RepackingSticker/StockOutDefinePackingForSticker";
import EditStockOutDefinePackingForSticker from "./pages/Sticker/RepackingSticker/EditStockOutDefinePackingForSticker";

import PrintInfoPanel from "./pages/Sticker/PrintInfoPanel";
//import SampleStickerPanel from "./pages/Sticker/SampleStickerPanel";
//Rakib
import DefinePending from "./pages/Invoices/DefinePending";
import AssignedTask from "./pages/Tasks/AssignedTask";
import IncompleteChecklistTask from "./pages/Tasks/IncompleteChecklistTask";
import EditTaskPendingChecklist from "./pages/Tasks/EditTaskPendingChecklist";
import CurrentStockReport from "./pages/Reports/CurrentStockReport";
import PendingAndCancelReport from "./pages/Reports/PendingAndCancelReport";
import DailyInvoiceTracking from "./pages/Reports/DailyInvoiceTracking";
import LoanDetails from "./pages/Reports/ProductLoan";


import AllowanceChecking from "./pages/Reports/AllowanceChecking";
import SampleStickerPanel from "./pages/Sticker/SampleStickerPanel";

//barcode
import StockInCrateBarcode from "./pages/BarCode/CreateBarcode";
import ListBarcodePrint from "./pages/BarCode/PrintBarcode";
import RepackingBocodeList from "./pages/BarCode/RepackingBarcodePrintList";
import CreateBarcodeRepack from "./pages/BarCode/CreateRepackingBarcode";
import StockOutBarcodeMap from "./pages/BarCode/StockOutBarcodeMap";

//stock In
import StockInInvoiceList from "./pages/stockin/StockInInvoiceList";

//Batch Report
import BatchReportStockOut from "./pages/Reports/BatchInfo/BatchReportStockOut";
import BatchReportForStockIn from "./pages/Reports/BatchInfo/BatchReportForStockIn";
import CustomerCreditReport from "./pages/Reports/CustomerCredit/CreditInfo"

// Stock IN Admin Action
import StockInAdminAction from "./pages/stockin/StockRevert/ListStockInApproved"
import StockInAdminRevert from "./pages/stockin/StockRevert/StockInAdminRevert"


//LC
import PiLists from "./pages/LetterOfCredit/PiLists"

const reportRoutes = [
  {
    path: "/report/sales",
    component: SalesReport,
  },
  {
    path: "/report/payment",
    component: PaymentReport,
  },
  {
    path: "/report/mapped",
    component: MappingReport,
  },
  {
    path: "/report/delivery-conveyance",
    component: DeliveryConveyanceReport,
  },
  {
    path: "/report/currentstock",
    component: CurrentStockReport,
  },
];

export const routes = [
  {
    path: "/signup",
    component: Signup,
  },
  {
    path: "/roles/users",
    component: UserRoles,
  },
  {
    path: "/",
    component: Dashboard,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/inhouseemployees",
    component: InhouseEmployeesdupli,
  },
  {
    path: "/addemployee",
    component: AddIhouseEmployeedupli,
  },
  {
    path: "/employee/:id/edit",
    component: EditEmployee,
  },
  {
    path: "/products",
    component: Products,
  },
  {
    path: "/products/sample/update",
    component: UpdateProductSample,
  },
  {
    path: "/addproduct",
    component: AddProduct,
  },
  {
    path: "/product/:id/edit",
    component: EditProduct,
  },
  {
    path: "/customers",
    component: Customers,
  },
  {
    path: "/addcustomer",
    component: AddCustomer,
  },
  {
    path: "/customer/:id/edit",
    component: EditCustomer,
  },
  {
    path: "/customers/map",
    component: CustomerMap,
  },
  {
    path: "/addsellprice",
    component: AddPrice,
  },
  {
    path: "/price",
    component: Price,
  },
  {
    path: "/price/:id/edit",
    component: EditPrice,
  },
  {
    path: "/manageroleandmenu",
    component: ManageRoleAndMenu,
  },
  {
    path: "/marketingactivities",
    component: MarketingActivities,
  },
  {
    path: "/addmarketingactivity",
    component: AddMarketingActivity,
  },
  {
    path: "/marketingactivity/:id/edit",
    component: EditMarketingActivity,
  },
  {
    path: "/marketing/report/map",
    component: MarketingReportMap,
  },
  {
    path: "/mappingproduct",
    component: MapProduct,
  },
  {
    path: "/geolocation",
    component: GeoLocation,
  },
  {
    path: "/salesorderinternal",
    component: SalesOrderInternal,
  },
  {
    path: "/salesorderinternal/create",
    component: CreateSalesOrderInternal,
  },
  
  // {
  //   path: "/marketing/report",
  //   component: MarketingReport,
  // },

  {
    path: "/report/marketingvisit",
    component: MarketingReport,
  },

  {
    path: "/tallysales/add",
    component: AddTallySales,
  },
  {
    path: "/salesorderinternal/:id/edit",
    component: EditSalesOrderInternal,
  },
  {
    path: "/salesorderinternal/:id/action",
    component: EscalateSalesOrderInternal,
  },
  {
    path: "/salesorderinternal/:id/package",
    component: PackagingSalesOrderInternal,
  },
  {
    path: "/salesorderinternal/escalationlist",
    component: EscalatedSalesOrderInternals,
  },
  {
    path: "/salesorderinternal/packagelist",
    component: PackagedSalesOrderInternal,
  },
  {
    path: "/salesorderinternal/packagelist/done",
    component: PackageDoneSalesOrderInternal,
  },
  {
    path: "/packagelist/done/:id/edit",
    component: EditPackagedSalesOrderInternal,
  },
  {
    path: "/suppliers/add",
    component: AddSupplier,
  },
  {
    path: "/supplier/:id/edit",
    component: EditSupplier,
  },
  {
    path: "/suppliers",
    component: Suppliers,
  },
  {
    path: "/assignemployeetocustomers",
    component: AssignCustomersToEmployee,
  },
  {
    path: "/stockin/create",
    component: CreateStockInForm,
  },
  {
    path: "/stockin/list",
    component: Stockin,
  },
  {
    path: "/stockin/unapprovedqty/list",
    component: StockinForConfirmation,
  },
  {
    path: "/stockin/:id/edit",
    component: EditStockin,
  },
  {
    path: "/stockin/:id/confirmQty",
    component: UpdateStockinQuantity,
  },
  {
    path: "/purchaseprices",
    component: PurchasePrice,
  },
  {
    path: "/purchaseprice/add",
    component: AddPurchasePrice,
  },
  {
    path: "/purchaseprice/:id/edit",
    component: EditPurchasePrice,
  },
  {
    path: "/batches",
    component: Batches,
  },
  {
    path: "/quotation/list",
    component: Quotations,
  },
  {
    path: "/quotation/unapprovedlist",
    component: QuotationUnapprovedList,
  },
  {
    path: "/quotation/approvedlist",
    component: QuotationApprovedList,
  },
  {
    path: "/quotation/create",
    component: CreateQuotation,
  },

  {
    path: "/quotation/:id/edit",
    component: EditQuotation,
  },
  {
    path: "/quotation/:id/approve",
    component: ApproveQuotation,
  },
  {
    path: "/cheque/list",
    component: Cheques,
  },
  {
    path: "/cheque/create",
    component: AddCheque,
  },
  {
    path: "/cheque/:id/edit",
    component: EditCheque,
  },
  {
    path: "/payment/list",
    component: Payments,
  },
  {
    path: "/payment/create",
    component: AddPayment,
  },
  {
    path: "/payment/:id/edit",
    component: EditPayment,
  },
  {
    path: "/task/create",
    component: AddTask,
  },
  {
    path: "/task/:id/edit",
    component: EditTask,
  },
  {
    path: "/task/:id/editpendingchecklist",
    component: EditTaskPendingChecklist,
  },
  {
    path: "/delivery/create",
    component: AddDelivery,
  },
  {
    path: "/task/incompletelist",
    component: IncompleteTask,
  },
  {
    path: "/task/assignedlist",
    component: AssignedTask,
  },
  {
    path: "/task/incompletechecklist",
    component: IncompleteChecklistTask,
  },

  {
    path: "/task/assign",
    component: UnAssignedTask,
  },
  {
    path: "/stockout/status",
    component: StockoutStatus,
  },
  /*{
    path: "/stockout/update",
    component: UpdateStockoutStatus,
  },*/
  {
    path: "/stockout/update",
    component: DefinePending,
  },
  {
    path: "/report/sales",
    component: SalesReport,
  },
  {
    path: "/report/delivery",
    component: DeliveryReport,
  },
  {
    path: "/report/payment",
    component: PaymentReport,
  },
  {
    path: "/report/mapped",
    component: MappingReport,
  },
  {
    path: "/report/delivery-conveyance",
    component: DeliveryConveyanceReport,
  },
  {
    path: "/report/currentstock",
    component: CurrentStockReport,
  },
  {
    path: "/report/productout",
    component: ProductStockOutReport,
  },
  {
    path: "/report/productin",
    component: ProductStockInReport,
  },
  {
    path: "/report/pending-and-cancel-report",
    component: PendingAndCancelReport,
  },
  {
    path: "/report/daily-invoice-tracking",
    component: DailyInvoiceTracking,
  },
  {
    path: "/salesorderinternal/general",
    component: SalesOrderInternalGeneralEdit,
  },
 
  {
    path: "/salesorder/limitededit",
    component: SalesOrderLimitedEdit,
  },
  {
    path: "/salesorder/:id/invoicelimitededit",
    component: LimitedItemsEidtAtInvoice,
  },
 
  {
    path: "/salesorderinternal/:id/action/price",
    component: EscalateSalesOrderInternalWithPriceEdit,
  },
  {
    path: "/salesorderinternal/admin/:id/edit",
    component: EditSalesOrderInternalAdmin,
  },
  {
    path: "/packagelist/done/admin/:id/edit",
    component: EditSalesOrderPacking,
  },
  {
    path: "/salesorderinternal/pendingprice",
    component: SalesPendingPrice,
  },

  {
    path: "/stockin/stockadjustment",
    component: CreatestockAdjustment,
  },
  {
    path: "/report/stockin/stockadjustmentreport",
    component: StockAdjustmentReport,
  },
  
  {
    path: "/customers/customerlist",
    component: CustomerList,
  
  },
  {
    path: "/packingessential/packingrelatedprint",
    component: MiniInvoiceLabelSticker,
  
  },

  {
    path: "/locationlist",
    component: LocationList,
  
  },
  {
    path:"/location/add",
    component: AddLocation
  },
  {
    path:"/location/:id/edit",
    component: EditLocation
  },
  {
    path: "/productfunctionlist",
    component: ListProductFunction,
  
  },
  {
    path:"/function/add",
    component: CreateProductFunction
  },
  {
    path:"/function/:id/edit",
    component: UpdateProductFunction
  },
  {
    path:"/stockoutpackagesticker/orderlist",
    component: SalesOrderPackageStickerList
  },
  {
    path: "/stockoutpackagesticker/:id/definepacking",
    component: StockOutDefinePackingForSticker,
  },
  {
    path: "/stockoutpackagesticker/:id/editdefinepacking",
    component: EditStockOutDefinePackingForSticker,
  },
  {
    path:"/sampleSticker/print",
    component:SampleStickerPanel,
  },
  {
    path:"/packing/printpanel",
    component:PrintInfoPanel
  },
  {
    path:"/allowance/checking",
    component:AllowanceChecking
  },

  {
    path:"/barcode/stockout",
    component:StockOutBarcodeMap
  },

  {
    path:"/barcode/listprint",
    component:ListBarcodePrint
  },
  {
    path:"/barcode/createbarcode",
    component:StockInCrateBarcode
  },
  {
    path:"/barcode/repackingbarcodelist",
    component:RepackingBocodeList
  },
  {
    path:"/barcode/createrepackingbarcode",
    component:CreateBarcodeRepack
  },
  {
    path:"/report/productloandetails",
    component:LoanDetails
  },
  {
    path:"/stockin/stockininvoicelist",
    component:StockInInvoiceList
  },

  {
    path:"/report/stockinbatchreport",
    component:BatchReportForStockIn
  },
  {
    path:"/report/stockoutbatchreport",
    component:BatchReportStockOut
  },
  {
    path:"/report/customercreditreport",
    component:CustomerCreditReport
  },
  {
    path:"/stockin/stockinadminaction",
    component:StockInAdminAction
  },
  //StockInAdminEdit
  {
    path:"/stockin/:id/stockinadminrevert",
    component:StockInAdminRevert
  },
  {
    path:"/lctracking/pilists",
    component:PiLists
  }
  ///...reportRoutes,
];

